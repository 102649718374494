

















import { InputSetups } from '../mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins } from 'vue-property-decorator'

import DistributionEditor
  from 'piramis-base-components/src/components/Distributions/DistributionEditor/DistributionEditor.vue'
import {
  DistributionConditions,
  DistributionEditorAction,
  MessageEditorProps
} from "piramis-base-components/src/components/Distributions/DistributionEditor/types"
import { CreatingDistribution, DistributionModel } from 'piramis-base-components/src/components/Distributions/types'
import { SuccessGetUser } from 'piramis-base-components/src/components/Flow/logic/types/type'
import { FlowsService } from '@/includes/services/FlowService'
import { DistributionService } from '@/includes/services/DistributionService'
import MetaDataService from '@/includes/services/MetaDataService'
import {
  errorNotificationWrapper,
  successNotificationWrapper
} from 'piramis-base-components/src/logic/helpers/NotificationService'

@Component({
  data() {
    return {
      DistributionEditorAction,
    }
  },
  components: {
    DistributionEditor,
  },
})
export default class DistributionItem extends Mixins(UseFields, InputSetups) {
  messageEditorProps: MessageEditorProps | null = null

  utmSources: Array<{ id: number, value: string }> = []

  readonlyDistribution: DistributionModel | null = null

  get pageAction(): DistributionEditorAction {
    return this.$route.params.actionType as DistributionEditorAction
  }

  getUsersInfo(values: Array<string | number>): Promise<Array<Omit<SuccessGetUser, 'status'>>> {
    return new Promise(resolve => {
      FlowsService.getUsersInfo('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, values })
        .then(({ data }) => resolve(data))
    })
  }

  preCheckDistribution(conditions: Array<DistributionConditions>): Promise<number> {
    return new Promise(resolve => {
      DistributionService.preCheckDistribution('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, conditions })
        .then(({ count }) => resolve(count))
    })
  }

  saveDistribution(distribution: CreatingDistribution): void {
    DistributionService.createDistribution('tg', Object.assign(distribution, { board_key: this.$store.state.boardsState.activeBoard!.board }))
      .then(() => {
        successNotificationWrapper(this.$t('success').toString(), '')
        this.goToList()
      })
      .catch((e) => errorNotificationWrapper('', e))
  }

  goToList(): void {
    this.$router.push({
      name: 'Distributions',
      params: {
        id: this.$route.params.id
      }
    })
  }

  created(): void {
    this.messageEditorProps = {
      defaultMessageEditorButtons: this.defaultMessageEditorButtons,
      editorButtonsHelpView: EditorButtonsHelpView,
      board_key: this.$store.state.boardsState.activeBoard!.board
    }

    MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard!.board })
      .then(({ items }) => this.utmSources = items)

    if (this.pageAction === DistributionEditorAction.watch) {
      const distributionId = Number.parseInt(this.$route.params.distributionId.toString())

      DistributionService.getDistribution('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, id: distributionId })
        .then(({ data }) => this.readonlyDistribution = data)
    }
  }

}
