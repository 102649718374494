import BaseApi from '@/includes/services/BaseApi'

import { CreatingDistribution, DistributionModel, DistributionState } from 'piramis-base-components/src/components/Distributions/types'
import { DistributionConditions } from 'piramis-base-components/src/components/Distributions/DistributionEditor/types'

export class DistributionService {
    static createDistribution(type:string, body: { board_key: string } & CreatingDistribution):Promise<{data: DistributionModel}> {
        return BaseApi.sendRequest(type, 'createdistribution', body)
    }

    static getDistributions(type:string, body: { board_key:string, limit?: number, offset?: number, state?: DistributionState }):Promise<{items: Array<DistributionModel>}> {
        return BaseApi.sendRequest(type, 'getdistributions', body)
    }

    static getDistribution(type:string, body: { board_key:string, id:number }):Promise<{data: DistributionModel}> {
        return BaseApi.sendRequest(type, 'getdistribution', body)
    }

    static preCheckDistribution(type: string, body: { board_key: string, conditions: Array<DistributionConditions> }): Promise<{ count: number }> {
        return BaseApi.sendRequest(type, 'PreCheckDistribution', body)
    }

    static cancelDistribution(type:string, body: { board_key:string, id:number }):Promise<void> {
        return BaseApi.sendRequest(type, 'cancelDistribution', body)
    }
}
